.chat-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

@media (max-width: 800px) {
    .chat-container  {
        margin-left: 12px;
        margin-right: 12px;
    }
  }

.history-container {
    margin-top: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
    row-gap: 10px;
}

.message-container {
    padding: 12px;
    border-radius: 8px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
}

.loading-container {
    padding: 12px;
    border-radius: 8px;
    background-color: #eeeeee;
}

.author-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}

.content-container {
    flex: 1;
}

.input-container {
    width: 100%;
    align-self: flex-end;
    margin-bottom: 16px;
    margin-top: 16px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: nowrap;
}

.chat-input {
    width: 100%;
    flex: 1;
    border: none;
    border: solid 1px #ccc;
    line-height: 1.2;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 'auto';
}

.markdown code {
    display: block;
    white-space: pre-wrap;
    background-color: #d5d5d5;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    border-radius: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.disclaimer {
    margin-top: 8px;
    font-size: 12px;
    font-style: italic;
    font-weight: lighter;
    text-align: center;
}
